<template>
  <div class="order">
    <van-tabs
      v-model="active"
      @click="tabsClick"
      line-width="20"
      line-height="2"
    >
      <van-tab title="全部" name="" />
      <van-tab title="待接单" name="0" />
      <van-tab title="进行中" name="1" />
      <van-tab title="待评价" name="3" />
      <van-tab title="售后" name="4" />
    </van-tabs>
    <van-pull-refresh v-model="isRefreshLoading" @refresh="onRefresh">
      <div class="order-data-div" v-if="orderList.length > 0">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            class="order-data-li"
            v-for="item in orderList"
            :key="item.id"
            @click.stop="orderDetails(item)"
          >
            <div class="info-div">
              <img :src="item.projectCoverImg" class="projectImg" alt="" />
              <div class="info">
                <p class="info-p">
                  <span class="name">{{ item.projectName }}</span>
                  <span class="status" v-if="item.payStatus == 0 && item.orderStatus != 2">待支付</span>
                  <span class="status" v-else>
                    <font v-if="
                          item.subStatus == 1
                        ">待接单</font>
                    <!-- <font v-if="item.orderStatus == 1 && 
                          item.afterStatus != 0"
                      >进行中</font> -->
                    <font 
                      v-if="
                        item.orderStatus == 1
                      ">进行中</font>
                    <font v-if="item.orderStatus == 2">交易关闭</font>
                    <font v-if="item.orderStatus == 3 && 
                          item.commentStatus == 1"
                      >待评价</font>
                    <font 
                        v-if="
                          item.orderStatus == 3 && 
                          item.commentStatus == 2 || 
                          item.orderStatus == 3
                        ">已完成</font>
                    <font v-if="item.refundStatus == 1">已关闭</font>
                  </span>
                </p>
                <p class="info-p">
                  <span class="time"
                    ><van-icon name="clock-o" class="timeIcon" />预约时间：{{
                      item.appointmentTime
                    }}</span
                  >
                </p>
                <p class="info-p">
                  <span class="price-span"
                    >金额<font class="price">￥{{ item.projectTotalPrice }}</font>
                    <font class="num">数量×{{ item.projectNum }}</font></span
                  >
                </p>
              </div>
            </div>
            <div class="operate-div">
              <!-- <span @click.stop="orderOperate(1,item)">再来一单</span> -->
              <span
                @click.stop="callTelShow = true"
                v-if="
                  item.orderStatus == 3 &&
                  item.afterStatus == 1
                ">申请售后</span>
              <span
                @click.stop="orderOperate(2, item)"
                v-if="
                  item.orderStatus == 3 && 
                  item.commentStatus == 1
                ">去评价</span>
              <span
                @click.stop="orderOperate(3, item)">删除订单</span>
              <span @click.stop="orderOperate(4, item)" 
                    v-if="
                    item.payStatus == 0 && 
                    item.orderStatus != 2
                  ">去支付</span>
              <!-- <span
                @click.stop="orderOperate(5, item)"
                v-if="
                  item.orderStatus == 1 
                  &&  item.artificerStatus == 4
                ">服务加钟</span> -->
              <span
                @click="pushWxkf"
                v-if="
                  item.orderStatus == 0 &&
                  item.artificerStatus == 0 &&
                  item.payStatus == 1
                ">取消订单</span>
            </div>
          </div>
        </van-list>
      </div>
      <div class="nullDataDiv" v-else>
        <img src="../assets/img/error.png" alt="" />
        <span class="text">暂无数据</span>
      </div>
    </van-pull-refresh>
    <callServiceTel
      :callTelShow="callTelShow"
      v-if="callTelShow"
      @telHide="callTelShow = false"
    />
  </div>
</template>

<script>
import callServiceTel from "@/components/callServiceTel";
import { myOrder, delOrder } from "@/api/order";
import { formatTime } from "@/utils/index";
export default {
  components: {
    callServiceTel
  },
  data() {
    return {
      isRefreshLoading:false,
      callTelShow: false,
      loading: false,
      finished: false,
      page: 1,
      total: 0,
      active: "",
      orderList: []
    };
  },
  created() {
    if (sessionStorage.getItem("orderTabActive")) {
      this.active = sessionStorage.getItem("orderTabActive");
    }
    if (localStorage.getItem("authorization")) {
      this.myOrder();
    }
  },
  methods: {
    pushWxkf(){
      window.location.href = "https://work.weixin.qq.com/kfid/kfc17cbbbde9caf850f"
    },
    // tab切换
    tabsClick() {
      this.page = 1;
      this.orderList = [];
      this.myOrder();
    },
    // 获取订单列表
    myOrder() {
      let data = {
        pageIndex: this.page,
        pageSize: 10,
        orderStatus: this.active
      };
      myOrder(data).then(res => {
        this.total = res.data.total;
        this.isRefreshLoading = false
        if (res.data.records.length > 0) {
          res.data.records.forEach(item => {
            let formData = formatTime(item.appointmentTime, 3);
            item.appointmentTime = `${formData.year}-${formData.month}-${formData.date} ${formData.h}:${formData.m}`;
          });
          this.orderList = this.orderList.concat(res.data.records);
          this.loading = false;
        } else {
          this.finished = true;
        }
      });
    },
    // 订单详情
    orderDetails(item) {
      console.log("item", item);
      this.$router.push({
        path: "/order/details",
        query: {
          order_id: item.orderId
        }
      });
    },
    // 订单操作 1 再来 2 评价 3 删除 4 去支付 5 服务加钟
    orderOperate(type, item) {
      if (type == 1) {
        this.$router.push({
          path: "/order/confirmOrder",
          query: {}
        });
      } else if (type == 2) {
        this.$router.push({
          path: "/order/evaluate",
          query: {
            artificerId: item.artificerId,
            orderId: item.orderId
          }
        });
      } else if (type == 3) {
        delOrder({ orderId: item.orderId }).then(res => {
          if (res.data) {
            this.$toast("删除成功");
            this.tabsClick();
          }
        });
      } else if (type == 4) {
        this.$router.push({
          path: "/order/details",
          query: {
            order_id: item.orderId
          }
        });
      } else if (type == 5) {
        this.$router.push({
          path: "/order/orderClock",
          query: {
            order_id: item.orderId
          }
        });
      }
    },
    // 分页
    onLoad() {
      if (this.orderList.length < this.total) {
        this.page++;
        this.myOrder();
      } else {
        this.finished = true;
      }
    },
    // 刷新
    onRefresh(){
      this.loading =  false;
      this.finished = false;
      this.page = 1;
      this.total = 0;
      this.orderList = [];
      this.myOrder();
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.path == "/order/details" || to.path == "/order/evaluate") {
      sessionStorage.setItem("orderTabActive", this.active);
    } else {
      sessionStorage.removeItem("orderTabActive");
    }
    next();
  }
};
</script>

<style lang="less" scoped>
.order {
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  background: #f1f1f1;
  .van-tabs {
    position: sticky;
    top: 0;
    z-index: 10;
  }
  .van-pull-refresh{
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .order-data-div {
    padding: 0.4rem .4rem 1.6rem;
    .order-data-li {
      &:not(:last-child) {
        margin-bottom: 0.2rem;
      }
      display: flex;
      flex-direction: column;
      background: white;
      padding: 0.3rem;
      border-radius: 0.2rem;
      .info-div {
        display: flex;
      }
      .projectImg {
        width: 2rem;
        height: 2rem;
        margin-right: 0.2rem;
      }
      .info {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .info-p {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .name {
            font-weight: bold;
            font-size: 0.36rem;
          }
          .status {
            color: rgb(211, 30, 30);
          }
          .time {
            color: rgb(90, 90, 90);
            font-size: 0.32rem;
          }
          .timeIcon {
            margin-right: 0.1rem;
          }
          .price-span {
            font-size: 0.32rem;
            color: gray;
          }
          .price {
            display: inline-block;
            width: 1.6rem;
            font-weight: bold;
            color: rgb(255, 166, 0);
          }
        }
      }
      .operate-div {
        margin-top: 0.2rem;
        display: flex;
        justify-content: flex-end;
        span {
          margin-left: 0.2rem;
          width: 1.5rem;
          height: 0.6rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.28rem;
          border: solid 1px #e3e3e3;
          border-radius: 0.3rem;
        }
      }
    }
  }
}
</style>
<style lang="less">
  .order{
    .van-pull-refresh__track{
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }
</style>
